import "animate.css";
import React from "react";
// eslint-disable-next-line import/extensions
import ReactDOM from "react-dom/client";

import { registerLicense } from "@syncfusion/ej2-base";

import { App } from "./App.tsx";
import "./styles/index.css";
import "./styles/reset.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<App />);

// Registering Syncfusion license key
registerLicense(window.appConfig.syncFusionKey);
